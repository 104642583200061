import * as React from 'react';

function SvgCustomStorybook(props) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='svg-icon'
			{...props}>
			<path
				opacity={0.3}
				d='M3.658 21.275L3 3.114c-.022-.6.42-1.109.997-1.146l14.87-.966c.588-.038 1.093.425 1.13 1.035l.002.068v19.79c0 .61-.477 1.105-1.065 1.105l-.048-.001-14.215-.664c-.554-.026-.996-.489-1.017-1.064l.003.004z'
				fill='currentColor'
			/>
			<path
				d='M16.642 3.731l.107-2.562L18.907 1 19 3.642a.167.167 0 01-.163.171.175.175 0 01-.11-.036l-.832-.65-.986.74a.17.17 0 01-.236-.032.167.167 0 01-.034-.108l.003.004zm-2.76 5.642c0 .434 2.949.226 3.345-.08 0-2.96-1.6-4.514-4.53-4.514S8.126 6.358 8.126 8.727c0 4.124 5.609 4.203 5.609 6.453 0 .632-.312 1.006-.998 1.006-.894 0-1.246-.452-1.205-1.992 0-.335-3.407-.439-3.511 0C7.755 17.924 10.098 19 12.778 19c2.597 0 4.633-1.374 4.633-3.86 0-4.422-5.693-4.304-5.693-6.494 0-.889.664-1.007 1.059-1.007.414 0 1.163.072 1.1 1.73l.005.004z'
				fill='currentColor'
			/>
		</svg>
	);
}

export default SvgCustomStorybook;
