const origin  = window.location.origin;
let domain = "https://api.dev.xrprompter.com";
let timeoutIdle = 1800000  // 30 minutos

if(origin == "https://dev.xrprompter.com" || origin == "https://www.dev.xrprompter.com"){
    domain = "https://api.dev.xrprompter.com"
    timeoutIdle = 1800000
}
if(origin == "https://qa.xrprompter.com" || origin == "https://www.qa.xrprompter.com"){
    domain = "https://api.qa.xrprompter.com"
}
if(origin == "https://xrprompter.com" || origin == "https://www.xrprompter.com"){
    domain = "https://api.xrprompter.com"
}
if(origin == "http://localhost:3000"){
    timeoutIdle = 1800000
}


if(origin == "https://xrprompterfront-dev.azurewebsites.net"){
    domain = "https://dev.api.xrprompter.com"
}
if(origin == "https://xrprompterfront-main.azurewebsites.net"){
    domain = "https://api.xrprompter.com"
}
if(origin == "https://xrprompterfront-qa.azurewebsites.net"){
    domain = "https://api.qa.xrprompter.com"
}

export const TIMEOUT_IDLE = timeoutIdle
export const DOMAIN = domain + "/api"
export const URL_LOGIN_AZURE = domain + "/login/azure"
export const MENSAJE_ERROR = 'Ha ocurrido un error inesperado. Intenta más tarde.'
