import * as React from 'react';

function SvgCustomSwift(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'
			width='1em'
			height='1em'
			className='svg-icon'
			{...props}>
			<path
				d='M512 256c0 58.399-19.55 112.232-52.485 155.314a257.979 257.979 0 01-8.359 10.376C404.198 476.944 334.19 512 256 512 114.615 512 0 397.385 0 256c0-61.994 22.037-118.857 58.723-163.15.376-.47.763-.93 1.139-1.379C106.83 35.547 177.267 0 256 0c141.385 0 256 114.615 256 256z'
				fill='#fe4216'
			/>
			<path
				d='M459.515 411.314a257.979 257.979 0 01-8.359 10.376c-9.091-14.597-23.353-29.027-44.743-31.556-45.568-5.402-62.945 25.172-110.895 31.169-187.016 23.97-285.069-136.077-285.069-136.077 79.193 60.583 187.705 74.553 257.63 29.895-.251-.167-.502-.345-.742-.512C176.41 250.483 80.875 123.214 58.723 92.85c-3.114-4.274-4.775-6.625-4.775-6.625 1.964 1.755 3.939 3.5 5.914 5.245 83.686 73.697 177.507 132.618 177.507 132.618C165.407 152.127 109.38 63.634 108.502 62.265 204.413 167.152 329.08 242.072 329.08 242.072c39.915-120.916-42.109-214.768-43.144-215.949 1.139.627 97.301 54.721 132.462 159.608 16.624 49.591 12.319 92.693 3.093 124.447v.01c31.89 35.245 39.8 75.954 38.024 101.126z'
				fill='#f2f2f2'
			/>
		</svg>
	);
}

export default SvgCustomSwift;
