import * as React from 'react';

function SvgCustomTurkey(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'
			width='1em'
			height='1em'
			className='svg-icon'
			{...props}>
			<path
				d='M400 0H112C50.144 0 0 50.144 0 112v288c0 61.856 50.144 112 112 112h288c61.856 0 112-50.144 112-112V112C512 50.144 461.856 0 400 0z'
				fill='#ff4b55'
			/>
			<g fill='#f5f5f5'>
				<path d='M277.992 211.684l15.663 20.489 24.456-8.186c1.367-.458 2.531 1.089 1.712 2.276l-14.647 21.227 15.343 20.73c.858 1.159-.253 2.743-1.635 2.331l-24.715-7.37-14.974 20.998c-.837 1.174-2.687.607-2.722-.835l-.628-25.782-24.597-7.752c-1.375-.434-1.408-2.368-.047-2.847l24.326-8.564-.228-25.789c-.013-1.443 1.817-2.071 2.693-.926zM191.378 325.906c-44.139 0-79.945-35.806-79.945-79.985 0-44.098 35.806-79.984 79.945-79.984 16.422 0 31.567 5.051 44.187 13.599 1.933 1.309 4.139-1.231 2.52-2.913-18.862-19.611-45.559-31.574-75.158-30.564-50.853 1.735-92.952 42.507-96.201 93.286-3.722 58.163 42.332 106.499 99.672 106.499 28.245 0 53.629-11.808 71.757-30.688 1.602-1.668-.609-4.169-2.523-2.872-12.633 8.565-27.802 13.622-44.254 13.622z' />
			</g>
		</svg>
	);
}

export default SvgCustomTurkey;
