import {Store} from 'react-notifications-component';
import Icon from "./components/icon/Icon";
import React from "react";
import moment from "moment";
import 'moment/locale/es';

export const _logout401 = () => {
    localStorage.clear()
    location.href = '/login'
}

export const _loader = () => {
    let overlay = document.createElement("div")
    overlay.id = "overlay-loader";
    overlay.className = "offcanvas-backdrop fade show";

    let wrap = document.createElement("div")
    wrap.className = "overlay__inner";

    let content = document.createElement("div")
    content.className = "overlay__content";

    let span = document.createElement("span")
    span.className = "spinner";

    content.appendChild(span)
    wrap.appendChild(content)
    overlay.appendChild(wrap)
    document.querySelector('body').append(overlay)
}

export const _removeLoader = () => {
    let ele = document.getElementById('overlay-loader')
    if (ele) {
        ele.remove()
    }
}

const _settings = {
    insert: 'top',
    container: 'bottom-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
        duration: 5000,
        pauseOnHover: true,
        onScreen: true,
        showIcon: true,
        waitForAnimation: true,
    },
};

export const _notify = (message, type = 'default') => {

    let icon = '';
    let stringTitle = ''
    if (type === 'success') {
        icon = 'CheckCircle'
        stringTitle = 'Exito'
    }
    if (type === 'danger') {
        icon = 'XCircle'
        stringTitle = 'Error'
    }
    if (type === 'warning') {
        icon = 'ExclamationTriangle'
        stringTitle = 'Atención'
    }
    if (type === 'info') {
        icon = 'InfoCircle'
        stringTitle = 'Info'
    }

    let title = <div style={{
        display: 'flex',
        alignItems: 'center',
    }}>
        <Icon icon={icon} style={{fontSize: '18px', color: 'white', marginRight: '5px'}}/>
        <p style={{fontSize: '14px', margin: '0px'}}>{stringTitle}</p>
    </div>

    let tpl = <p style={{
        fontSize: '14px',
        margin: '0px'
    }}>{message}</p>

    message = tpl

    Store.addNotification({
        title,
        message,
        type,
        ..._settings,
    });
};

export const _headers = () => {
    return {
        headers: {
            // 'Content-Type' : 'application/x-www-form-urlencoded',
            'Content-Type': 'application/json',
            // 'X-Requested-With' : 'XMLHttpRequest'
        }
    }
}

export const _getAuthHeaders = () => {
    return {
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('_myToken')
        }
    };
}

export const _getAuthHeadersData = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem('_myToken')
        }
    };
}


export const _undefined = (parametro) => {
    return (
        typeof parametro === undefined ||
        parametro === undefined ||
        parametro === '' ||
        parametro === "" ||
        parametro === null ||
        parametro === 'null' ||
        parametro === 0 ||
        parametro === "0"
    ) ? true : false
}

export const _isNull = (parametro) => {
    return (
        typeof parametro === 'undefined' ||
        typeof parametro === undefined ||
        parametro === undefined ||
        parametro === null ||
        parametro === 'null'
    ) ? true : false
}

export const _getMonth = (nmonth) => {
    switch (nmonth) {
        case 0:
            return 'Enero';
        case 1:
            return 'Febrero';
        case 2:
            return 'Marzo';
        case 3:
            return 'Abril';
        case 4:
            return 'Mayo';
        case 5:
            return 'Junio';
        case 6:
            return 'Julio';
        case 7:
            return 'Agosto';
        case 8:
            return 'Septiembre';
        case 9:
            return 'Octubre';
        case 10:
            return 'Noviembre';
        default:
            return 'Diciembre'
    }
}


export const _getParamUrl = (param, url) => {
    param = param.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + param + "=([^&#]*)"),
        results = regex.exec(url);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}


export const _handleValidations = (data, formState) => {

    let msjObligatorio = "Campo obligatorio.";
    let msjEmail = "Correo invalido."

    let dataError = []
    data.map((ele, index) => {
        // if(ele.length > 0){
        ele.map(validacion => {
            if (validacion.data.length > 0) {
                validacion.data.map(v => {
                    if (v == 'required') {
                        if (_undefined(formState[validacion.id])) {
                            let spanError = document.getElementById('err_' + validacion.id);
                            if (spanError) {
                                spanError.innerText = msjObligatorio
                                spanError.style.display = 'block'
                            }
                            !dataError.includes(index) && dataError.push(index)
                        }
                    }
                })
            }
        })
        // }
    })

    return {
        isValid: dataError.length > 0 ? false : true,
        indexError: dataError.length > 0 ? dataError[0] : null
    }
}


export const _cleanSpanError = (value, input) => {
    if (!_isNull(value)) {
        let spanError = document.getElementById('err_' + input)
        if (spanError) {
            spanError.innerText = ''
            spanError.style.display = 'none'
        }
    }
}


export const _subString = (cadena, length) => {
    let elipsis = ""
    if (!_undefined(cadena)) {
        if (cadena.length > length) {
            elipsis = "...";
        }
        cadena = cadena.substr(0, length)
        return cadena + elipsis
    } else {
        return cadena;
    }
}


const _escapeRegExp = (str) => {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}
export const _replace_all_to_one = (str, find, replace) => {
    for (var i = 0; i < find.length; i++) {
        str = str.replace(new RegExp(_escapeRegExp(find[i]), 'g'), replace);
        // return str.replace(new RegExp(this._escapeRegExp(find), 'g'), replace);
    }
    return str;
}

export const _getParam = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const _momentDate = (date) => {
    let dt = moment(date)
    dt.locale('es')

    let fecha = dt.format("LL")
    let hora = moment(date).format("h:mm a")

    return fecha + ' ' + hora
}

export const _momentDateOnly = (date) => {
    let dt = moment(date)
    dt.locale('es')

    let fecha = dt.format("LL")

    return fecha
}

export const _currentDate = () => {

    let date = moment().format('YYYY/MM/DD');
    return date
}

export const _momentDateFormat = (date) => {
    return moment(date).format('L')
}


export const _user = (key) => {
    let obj = localStorage.getItem('_dataUser');
    if (_undefined(obj)) {
        return location.href = '/login'
    }
    let data = JSON.parse(obj)
    let result = ''
    if (key == 'nombre') {
        result = data.name
    }
    if (key == 'rol') {
        result = data.rol
        // result = 'Super Admin'
    }

    return result
}
