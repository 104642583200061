import React, {useState,useRef,useEffect } from 'react';
import { Link} from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';

import logotipo from '../../assets/logos/logo-blanco.png'
import logoMicrosoft from '../../assets/logos/logo-microsoft.png'
import {_undefined, _notify, _headers, _loader, _removeLoader, _getParam} from "../../helpers";
import axios from "axios";
import {DOMAIN, MENSAJE_ERROR,URL_LOGIN_AZURE} from "../../config";
import Icon from "../../components/icon/Icon";

const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5 color-white'>Bienvenido</div>
			<div style={{color: 'white'}} className='text-center h4 mb-5'>Inicia sesión para continuar</div>
		</>
	);
};

const Index = () => {

	const { darkModeStatus } = useDarkMode();
	const [validUsernameInput, setValidUsernameInput] = useState(false);
	const [usernameInput, setUsernameInput] = useState('');
	const [passwordInput, setPasswordInput] = useState('');
	const refPassword = useRef(null);


	useEffect(() => {
		const dataParam = _getParam("data")
		if (!_undefined(dataParam)) {
			let decode = atob(dataParam)
			let data = JSON.parse(decode)

			// debugger
			if(!data.error){
				_notify(data.mensaje,'success')
				localStorage.setItem('_myToken',data.access_token)
				localStorage.setItem('_dataUser', JSON.stringify(data.data));
				location.href='/'
				return;
			}else{
				return _notify(data.mensaje,"danger")
			}

		}
	},[])


	const onKeyDownPassword = (evt)=>{
		if (evt.key === 'Enter') {
			evt.preventDefault();
			handleLogin()
		}
	}

	const onKeyDownUsername = (evt)=>{
		if (evt.key === 'Enter') {
			evt.preventDefault();
			if(_undefined(usernameInput)){
				return _notify('Introduce tu usuario.','danger')
			}
			setValidUsernameInput(true)
		}
	}

	const cambiarUsuario =()=>{
		setValidUsernameInput(false)
	}

	const handleOnChangeUsername = (evt) =>{
		let value = evt.target.value
		setUsernameInput(value)
	}

	const handleOnChangePassword = (evt) =>{
		let value = evt.target.value
		setPasswordInput(value)
	}

	const handleContinue = () =>{
		if(_undefined(usernameInput)){
			return _notify('Introduce tu usuario.','danger')
		}
		setValidUsernameInput(true)
	}

	const handleLogin = () =>{
		if(_undefined(usernameInput)){
			return _notify('Introduce tu usuario.','danger')
		}
		if(_undefined(passwordInput)){
			return _notify('Introduce tu contraseña.','danger')
		}

		let data = {email: usernameInput, password: passwordInput}

		_loader()
		axios.post(DOMAIN + '/auth/login',data,_headers()).then(response=>{
			console.log(response)
			response = response.data
			if(!response.error){
				_notify(response.mensaje,'success')
				localStorage.setItem('_myToken',response.access_token)
				localStorage.setItem('_dataUser', JSON.stringify(response.data));
				location.href='/'
			}else{
				_notify(response.mensaje,'danger')
			}
			_removeLoader()
		}).catch(error=>{
			_removeLoader()
			_notify(MENSAJE_ERROR,'danger')
		});
	}

	return (
		<PageWrapper
			title='Login'
			className='bg-login'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-darkn glass' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/login'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<img src={logotipo} style={{width:'260px'}} />
									</Link>
								</div>

								<LoginHeader />

								<br/>

								<div style={{
									width:"100%",
									display:"flex",
									alignItems:"center",
									justifyContent:"center"
								}}>
									<a
										href={URL_LOGIN_AZURE}
										// target="_blank"
										className={"btn-login-microsoft"}
										style={{
											width:"240px",
											height:"55px",
											display:"flex",
											alignItems:"center",
											justifyContent:"center",
											textDecoration:'none',
											color:"white",
											backgroundColor: 'rgb(23,62,130)'
										}}
									>
										<img src={logoMicrosoft}
										style={{width:"45px"}}
										></img>
										<span style={{
											fontSize:"16px"
										}}>Sign in with Microsoft</span>
									</a>
								</div>


								{/*<form className='row g-4 form-login pd20'>*/}
								{/*		<div className='col-12'>*/}
								{/*			{!validUsernameInput ? (*/}
								{/*				<FormGroup*/}
								{/*					id='login-username'*/}
								{/*					isFloating*/}
								{/*					label='Ingresa tu usuario'>*/}
								{/*					<Input*/}
								{/*						autoFocus*/}
								{/*						onKeyDown={(evt)=>onKeyDownUsername(evt)}*/}
								{/*						className="in-login"*/}
								{/*						value={usernameInput}*/}
								{/*						onChange={(evt) => { handleOnChangeUsername( evt) }}*/}
								{/*					/>*/}
								{/*				</FormGroup>*/}
								{/*			) : (*/}
								{/*				<div>*/}
								{/*					<div*/}
								{/*						onClick={()=>cambiarUsuario()}*/}
								{/*						style={{display:'flex',alignItems:"center",padding:"10px 0px",cursor:'pointer'}}*/}
								{/*					>*/}
								{/*						<Icon className="f35 color-white" icon='ArrowBack' />*/}
								{/*						<p className="m0 ml-5 color-white">Cambiar usuario</p>*/}
								{/*					</div>*/}
								{/*					<FormGroup*/}
								{/*					id='login-password'*/}
								{/*					isFloating*/}
								{/*					label='Ingresa tu contraseña'>*/}
								{/*						<Input*/}
								{/*							autoFocus*/}
								{/*							ref={refPassword}*/}
								{/*							onKeyDown={(evt)=>onKeyDownPassword(evt)}*/}
								{/*							type='password'*/}
								{/*							className="in-login"*/}
								{/*							value={passwordInput}*/}
								{/*							onChange={(evt) => { handleOnChangePassword( evt) }}*/}
								{/*						/>*/}
								{/*					</FormGroup>*/}
								{/*				</div>*/}
								{/*			)}*/}
								{/*		</div>*/}
								{/*		<div className='col-12'>*/}
								{/*			{!validUsernameInput ? (*/}
								{/*				<Button*/}
								{/*					className='w-100 py-3 button-login'*/}
								{/*					onClick={() => handleContinue()}>*/}
								{/*					Continuar*/}
								{/*				</Button>*/}
								{/*			) : (*/}
								{/*				<Button*/}
								{/*					className='w-100 py-3 button-login'*/}
								{/*					onClick={()=>handleLogin()}>*/}
								{/*					Iniciar sesión*/}
								{/*				</Button>*/}
								{/*			)}*/}
								{/*		</div>*/}
								{/*</form>*/}
								<br/>
								<br/>
								<br/>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Index;
