import React, { lazy } from 'react';
import {
	componentsMenu,
	dashboardMenu,
	demoPages,
	layoutMenu,
	menuPacientes,
	menuProtocolos,
	menuCuentas
} from '../menu';
import Login from '../pages/auth/login';

const PERFIL = {
	MI_PERFIL: lazy(() => import('../pages/perfil/perfil/perfil')),
};


const MENU_PACIENTES = {
	NUEVO_PACIENTE: lazy(() => import('../pages/menu-pacientes/nuevo-paciente/nuevoPaciente')),
	EDITAR_PACIENTE: lazy(() => import('../pages/menu-pacientes/editar-paciente/editarPaciente')),
	DETALLE_PACIENTE: lazy(() => import('../pages/menu-pacientes/detalle-paciente/detallePaciente')),
	EXPEDIENTES_CLINICOS: lazy(() => import('../pages/menu-pacientes/expedientes-clinicos/expedientesClinicos')),
};

const MENU_PROTOCOLOS = {
	NUEVO_PROTOCOLO: lazy(() => import('../pages/menu-protocolos/nuevo-protocolo/nuevoProtocolo')),
	PROTOCOLOS_MEDICOS: lazy(() => import('../pages/menu-protocolos/protocolos-medicos/protocolosMedicos')),
	DETALLE_PROTOCOLO: lazy(() => import('../pages/menu-protocolos/detalle-protocolo/detalleProtocolo')),
};
const MENU_CUENTAS = {
	NUEVO_USUARIO: lazy(() => import('../pages/menu-cuentas/nuevo-usuario/nuevoUsuario')),
	CUENTAS_DE_PERSONAL: lazy(() => import('../pages/menu-cuentas/cuentas-personal/cuentasPersonal')),
	DETALLE_USUARIO: lazy(() => import('../pages/menu-cuentas/detalle-usuario/detalleUsuario')),
}

const REPORTES = {
	CREAR_REPORTE: lazy(()=> import('../pages/reportes/crear-reporte/crearReporte')),
	DETALLE_REPORTE: lazy(()=> import('../pages/reportes/detalle-reporte/detalleReporte')),
	REPORTES: lazy(()=> import('../pages/reportes/reportes/reportes')),
	ANALISIS_E_INFORMES: lazy(()=> import('../pages/insights-reportes/insights-reportes/insights-reportes'))
}

const INSIGHTS_REPORTES = {
	INSIGHTS_REPORTES: lazy(()=> import('../pages/insights-reportes/insights-reportes/insights-reportes'))
}


const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation = [

	/**
	 * Menu pacientes
	 */

	//OCULTAR MENU NUEVO PACIENTE
	// {
	// 	path: menuPacientes.nuevoPaciente.path,
	// 	element: <MENU_PACIENTES.NUEVO_PACIENTE />,
	// 	exact: true,
	// },
	{
		path: 'control-de-pacientes/editar-paciente',
		element: <MENU_PACIENTES.EDITAR_PACIENTE />,
		exact: true,
	},

	{
		path: '/',
		element: <MENU_PACIENTES.EXPEDIENTES_CLINICOS />,
		exact: true,
	},

	{
		path: menuPacientes.expedientesClinicos.path,
		element: <MENU_PACIENTES.EXPEDIENTES_CLINICOS />,
		exact: true,
	},
	{
		path: 'control-de-pacientes/detalle-paciente',
		element: <MENU_PACIENTES.DETALLE_PACIENTE />,
		exact: true,
	},

	/**
	 * Menu protocolos
	 */
	{
		path: menuProtocolos.nuevoProtocolo.path,
		element: <MENU_PROTOCOLOS.NUEVO_PROTOCOLO />,
		exact: true,
	},

	{
		path: menuProtocolos.protocolosMedicos.path,
		element: <MENU_PROTOCOLOS.PROTOCOLOS_MEDICOS />,
		exact: true,
	},

	{
		path: 'control-de-protocolos/protocolos-medicos/detalle-protocolo',
		element: <MENU_PROTOCOLOS.DETALLE_PROTOCOLO />,
		exact: true,
	},

	/**
	 * Menu cuentas
	 */

	{
		path: menuCuentas.nuevoUsuario.path,
		element: <MENU_CUENTAS.NUEVO_USUARIO />,
		exact: true,
	},

	{
		path: menuCuentas.cuentasDePersonal.path,
		element: <MENU_CUENTAS.CUENTAS_DE_PERSONAL />,
		exact: true,
	},
	{
		path: 'control-de-usuarios/cuentas-de-personal/detalle-usuario',
		element: <MENU_CUENTAS.DETALLE_USUARIO />,
		exact: true,
	},

	/**
	 * Reportes
	 */

	{
		path: '/ejecutar-protocolo',
		element: <REPORTES.CREAR_REPORTE />,
		exact: true,
	},

	{
		path: 'reportes/detalle-reporte',
		element: <REPORTES.DETALLE_REPORTE />,
		exact: true,
	},
	{
		path: 'reportes/reportes',
		element: <REPORTES.REPORTES />,
		exact: true,
	},
	{
		path: 'reportes/analisis-e-informes',
		element: <REPORTES.ANALISIS_E_INFORMES />,
		exact: true,
	},


	/**
	 * Insights y Reportes
	 */

	{
		path: '/insights-reportes/insights-reportes',
		element: <INSIGHTS_REPORTES.INSIGHTS_REPORTES />,
		exact: true,
	},



	/**
	 * Perfil
	 */
	{
		path: '/mi-perfil',
		element: <PERFIL.MI_PERFIL />,
		exact: true,
	},















	/**
	 * Landing
	 */


	// {
	// 	path: dashboardMenu.dashboard.path,
	// 	element: <LANDING.DASHBOARD />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.dashboardBooking.path,
	// 	element: <LANDING.DASHBOARD_BOOKING />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.summary.path,
	// 	element: <LANDING.SUMMARY />,
	// 	exact: true,
	// },

	/** ************************************************** */

	/**
	 * Pages
	 */

	/**
	 * Single Pages
	 */
	// {
	// 	path: demoPages.singlePages.subMenu.boxedSingle.path,
	// 	element: <SINGLE.BOXED />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.singlePages.subMenu.fluidSingle.path,
	// 	element: <SINGLE.FLUID />,
	// 	exact: true,
	// },

	/**
	 * List
	 */
	// {
	// 	path: demoPages.listPages.subMenu.listBoxed.path,
	// 	element: <LIST.BOXED />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.listPages.subMenu.listFluid.path,
	// 	element: <LIST.FLUID />,
	// 	exact: true,
	// },

	/**
	 * Grid
	 */
	// {
	// 	path: demoPages.gridPages.subMenu.gridBoxed.path,
	// 	element: <GRID.BOXED />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.gridPages.subMenu.gridFluid.path,
	// 	element: <GRID.FLUID />,
	// 	exact: true,
	// },

	/**
	 * Edit
	 */
	// {
	// 	path: demoPages.editPages.subMenu.editBoxed.path,
	// 	element: <EDIT.BOXED />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editFluid.path,
	// 	element: <EDIT.FLUID />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editWizard.path,
	// 	element: <EDIT.WIZARD />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editInCanvas.path,
	// 	element: <EDIT.IN_CANVAS />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editInModal.path,
	// 	element: <EDIT.IN_MODAL />,
	// 	exact: true,
	// },

	/**
	 * END - Pages
	 */

	/**
	 * Auth Page
	 */
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},

	/**
	 * App
	 */

	/**
	 * App > Project Management
	 */
	// {
	// 	path: demoPages.projectManagement.subMenu.list.path,
	// 	element: <APP.PROJECT_MANAGEMENT.PROJECTS_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.projectManagement.subMenu.itemID.path}/:id`,
	// 	element: <APP.PROJECT_MANAGEMENT.PROJECT />,
	// 	exact: true,
	// },

	/**
	 * App > Knowledge
	 */
	// {
	// 	path: demoPages.knowledge.subMenu.grid.path,
	// 	element: <APP.KNOWLEDGE.GRID />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
	// 	element: <APP.KNOWLEDGE.VIEW />,
	// 	exact: true,
	// },

	/**
	 * App > Sales
	 */
	// {
	// 	path: demoPages.sales.subMenu.transactions.path,
	// 	element: <APP.SALES.TRANSACTIONS />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.sales.subMenu.salesList.path,
	// 	element: <APP.SALES.PRODUCTS />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.sales.subMenu.productsGrid.path,
	// 	element: <APP.SALES.PRODUCTS_GRID />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.sales.subMenu.productID.path}/:id`,
	// 	element: <APP.SALES.PRODUCTS_VIEW />,
	// 	exact: true,
	// },

	/**
	 * App > Appointment
	 */
	// {
	// 	path: demoPages.appointment.subMenu.calendar.path,
	// 	element: <APP.APPOINTMENT.CALENDAR />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.appointment.subMenu.employeeList.path,
	// 	element: <APP.APPOINTMENT.EMPLOYEE_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.appointment.subMenu.employeeID.path}/:id`,
	// 	element: <APP.APPOINTMENT.EMPLOYEE_VIEW />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.appointment.subMenu.appointmentList.path,
	// 	element: <APP.APPOINTMENT.APPOINTMENT_LIST />,
	// 	exact: true,
	// },

	/**
	 * App > CRM
	 */
	// {
	// 	path: demoPages.crm.subMenu.dashboard.path,
	// 	element: <APP.CRM.CRM_DASHBOARD />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.crm.subMenu.customersList.path,
	// 	element: <APP.CRM.CUSTOMERS />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.crm.subMenu.customerID.path}/:id`,
	// 	element: <APP.CRM.CUSTOMER />,
	// 	exact: true,
	// },

	/**
	 * App > Chat
	 */
	// {
	// 	path: demoPages.chat.subMenu.withListChat.path,
	// 	element: <APP.CHAT.WITH_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.chat.subMenu.onlyListChat.path,
	// 	element: <APP.CHAT.ONLY_LIST />,
	// 	exact: true,
	// },

	/**
	 * END - App
	 */

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	// {
	// 	path: layoutMenu.blank.path,
	// 	element: <PAGE_LAYOUTS.BLANK />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
	// 	element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
	// 	element: <PAGE_LAYOUTS.HEADER />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
	// 	element: <PAGE_LAYOUTS.SUBHEADER />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlyContent.path,
	// 	element: <PAGE_LAYOUTS.CONTENT />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.asideTypes.subMenu.defaultAside.path,
	// 	element: <PAGE_LAYOUTS.ASIDE />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
	// 	element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	// 	exact: true,
	// },
];
const contents = [...presentation];

export default contents;
