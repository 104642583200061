import React, {
	useContext, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import {
	menuPacientes,
	menuProtocolos,
	menuReportes,
} from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import Icon from '../../components/icon/Icon';
import { Link } from 'react-router-dom';
import { _getAuthHeaders, _loader, _logout401, _notify, _removeLoader, _user } from '../../helpers';
import Modal, { ModalBody, ModalHeader } from '../../components/bootstrap/Modal';
import QRCode from 'react-qr-code';
import axios from 'axios';
import { DOMAIN } from '../../config';

import iconManual from '../../assets/icons/icon-manual-2.png';


const Aside = () => {

	const [openModal, setOpenModal] = useState(false);
	const [stateStringQr, setStateStrinQr] = useState('');


	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	// const [doc, setDoc] = useState(false);

	// const { t } = useTranslation(['translation', 'menu']);

	// const { darkModeStatus } = useDarkMode();


	const getQr = () => {
		let url = DOMAIN + '/genera_token';

		_loader();
		axios.get(url, _getAuthHeaders()).then(response => {
			console.log('response', response);
			if (!response.data.error) {
				let string = JSON.stringify(response.data);
				setStateStrinQr(string);
				handleOpenModal(true);
			} else {
				return _notify(response.data.message, 'danger');
			}
			_removeLoader();
		}).catch(error => {
			_removeLoader();
			if (error?.response?.status == 401) {
				_logout401();
			}
		});


	};
	const handleOpenModal = (val) => {
		setOpenModal(val);
	};


	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className="aside-head">
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className="aside-body" style={{position:'relative'}}>

					<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Link to="/ejecutar-protocolo"
							  className="btn-crear-reporte"
							  style={{
								  width: asideStatus ? '88%' : '50px',
								  textDecoration: 'none',
								  color: 'white',
							  }}>

							{asideStatus && <span>Ejecutar Protocolo</span>}
							<Icon icon="Plus" size="2x" />
						</Link>
					</div>


					<NavigationLine />
					<Navigation menu={menuPacientes} id="menu-pacientes" />

					<NavigationLine />
					<Navigation menu={menuProtocolos} id="menu-protocolos" />

					{_user('rol') == 'Super Admin se oculto' && (
						<>
							{/*<NavigationLine/>*/}
							{/*<Navigation menu={menuCuentas} id='menu-cuentas' />*/}
						</>
					)}

					{(_user('rol') == 'Super Admin' || _user('rol') == 'Coordinador') && (
						<>
							<NavigationLine />
							<Navigation menu={menuReportes} id="menu-reportes" />
						</>
					)}

					<NavigationLine />

					{/*<>*/}
					{/*	<NavigationLine/>*/}
					{/*	<Navigation menu={menuInsightsReportes} id='menu-insights-reportes' />*/}
					{/*</>*/}


					<br />
					<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<div
							onClick={() => getQr()}
							className="btn-crear-reporte"
							style={{
								cursor: 'pointer',
								width: asideStatus ? '88%' : '50px',
								textDecoration: 'none',
								color: 'white',
							}}>

							{asideStatus && <span style={{ paddingRight: '5px' }}>QR Login</span>}
							<Icon icon="Login" size="2x" />
						</div>
					</div>

<br/>
					<nav aria-label="menu-manual" className={"elemento-final"}>
						<ul id="menu-manual" className="navigation">
							<li className="navigation-item">
								<a
									className="navigation-link navigation-link-pill"
									href="https://sftpstgwczgcfvphzzka.blob.core.windows.net/public-xrprompter/Manual%20Promptxr.pdf"
									target="_blank"
									rel="noopener noreferrer"
									download={'Manual-Web.pdf'}
								>

									<span className="navigation-link-info">
										<img alt="img" className="img-icon" src={iconManual} />
									<span className="navigation-text"
										  style={{ marginLeft: '1rem' }}>Manual de Usuario</span></span></a>
							</li>
							{/*<li className="navigation-item">*/}
							{/*	<a*/}
							{/*		className="navigation-link navigation-link-pill"*/}
							{/*		href={"https://sftpstgwczgcfvphzzka.blob.core.windows.net/public-xrprompter/Manual%20de%20Usuario%20XR%20Prompter%20AR%20Oct%202022.pdf"}*/}
							{/*		target="_blank"*/}
							{/*		rel="noopener noreferrer"*/}
							{/*		download={'Manual-AR.pdf'}*/}
							{/*	>*/}

							{/*		<span className="navigation-link-info">*/}
							{/*			<img alt="img" className="img-icon" src={iconManual} />*/}
							{/*		<span className="navigation-text"*/}
							{/*			  style={{ marginLeft: '1rem' }}>Manual AR</span></span></a>*/}
							{/*</li>*/}
						</ul>
					</nav>
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className="aside-drag-area" ref={constraintsRef} />
					<Tooltips title="Toggle Aside" flip={['top', 'right']}>
						<motion.div
							className="aside-touch"
							drag="x"
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}


			<Modal
				setIsOpen={setOpenModal}
				isOpen={openModal}
				titleId="modal-qr"
				isCentered
				isScrollable
				size="sm"
				id={'modal-qr'}
			>
				<ModalHeader setIsOpen={setOpenModal}>
				</ModalHeader>
				<ModalBody className="modal-qr">
					<div className={'content-modal'} style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
					}}>
						{/*<p className="title-modal">Login QR</p>*/}
						{/*<p className="msg-modal-eliminar"></p>*/}


						<div style={{ width: '100%', background: 'white', padding: '16px' }}>
							<QRCode
								size={256}
								// style={{ height: "100%", width:"500px"}}
								// value={`'${stateStringQr}'`}
								value={stateStringQr}
								viewBox={`0 0 256 256`}
								className={'c-qr'}
							/>
						</div>


						{/*<img style={{width:"260px"}} src={iconQr}/>*/}


						<p style={{
							width: '100%',
							marginTop: '15px',
							textAlign: 'center',
						}}>
							Escanea éste código con los lentes VUZIX para activar el protocolo y continuar el
							procedimiento.
						</p>

						{/*<p>{`'${stateStringQr}'`}</p>*/}

						{/*<div style={{*/}
						{/*	background:"#b9b9b9",*/}
						{/*	color:"white",*/}
						{/*	borderRadius:"8px",*/}
						{/*	padding:"10px",*/}
						{/*	fontSize:"16px",*/}
						{/*	width:"200px",*/}
						{/*	cursor:"pointer",*/}
						{/*	display:"flex",*/}
						{/*	alignItems:"center",*/}
						{/*	justifyContent:"center",*/}
						{/*	margin:"15px 0px"*/}

						{/*}}*/}

						{/*onClick={()=>setOpenModal(false)}*/}
						{/*>*/}
						{/*	Cancelar*/}
						{/*</div>*/}


					</div>

				</ModalBody>
			</Modal>

		</>
	);
};

export default Aside;
